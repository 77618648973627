import { uppyMultipleFileUpload } from "../uppyFileUpload";
import baguetteBox from "baguettebox.js";

require('baguettebox.js/dist/baguetteBox.css')

document.addEventListener("DOMContentLoaded", event => {
  baguetteBox.run("[data-image-lightbox]");
  Array.from(document.querySelectorAll('.uppy-upload-file')).forEach((fileInput) => {
    uppyMultipleFileUpload(fileInput)
  })
})
