const Uppy = require('@uppy/core')
const XHRUpload = require('@uppy/xhr-upload')
const Dashboard = require('@uppy/dashboard')
const ProgressBar = require('@uppy/progress-bar')
const Informer = require('@uppy/informer')
const ThumbnailGenerator = require('@uppy/thumbnail-generator')
const FileInput = require('@uppy/file-input')
const AwsS3 = require('@uppy/aws-s3')

require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')

export function uppyMultipleFileUpload(fileInput) {
  var uppyTarget = fileInput.parentNode
  var formGroup  = fileInput.closest("[data-photo-upload]")
  var uploadedPhotosLabel = formGroup.querySelector("[data-photo-upload-label]")
  var noPhotoLabel = uploadedPhotosLabel.querySelector('[data-no-photo-label]')

  var fieldNameTemplate = fileInput.name

  var uppy = fileUpload(fileInput)

  var openButton = formGroup.querySelector("[data-photo-upload-button]")
  openButton.addEventListener("click", (e) => {
    e.preventDefault()
  })

  uploadedPhotosLabel.addEventListener("click", (e) => {
    if (!e.target.dataset.revomeUploadedFile) {
      return
    }
    e.preventDefault()
    e.target.closest('.uploaded-file').remove()
    toggleNoPhotoLable(noPhotoLabel)

    if (e.target.dataset.revomeUploadedFile != 'saved') {
      uppy.removeFile(e.target.dataset.revomeUploadedFile)
    }
  })

  uppy
    .use(Dashboard, {
      target: uppyTarget,
      trigger: openButton,
      inline: false,
      replaceTargetContent: true,
      closeModalOnClickOutside: true,
      closeAfterFinish: true,
      showLinkToFileUploadResult: true,
      hidePauseResumeButton: true,
      hideCancelButton: true,
      disablePageScrollWhenModalOpen: false,
      showSelectedFiles: true,
    })

  var index = new Date().getTime()

  uppy.on('upload-success', function (file, response) {
    var hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = fieldNameTemplate.replace(/\[index\]/, `[${index}]`).replace(/\[\]/, '')
    hiddenField.value = uploadedFileData(file, response, fileInput)

    var fileLabel = document.createElement('div')
    fileLabel.classList.add("uploaded-file")
    var domString = `<div class="file-name">${file.name}</div>&nbsp;<a href="#" data-revome-uploaded-file=${file.id} class="photo-remove">×</a>`;
    fileLabel.innerHTML = domString;
    fileLabel.appendChild(hiddenField)

    index += 1

    uploadedPhotosLabel.appendChild(fileLabel)
    toggleNoPhotoLable(noPhotoLabel)
  })
}

function toggleNoPhotoLable(label) {
  var imageCnt = label.closest('[data-photo-upload]').querySelectorAll('.uploaded-file').length
  if (imageCnt > 0) {
    label.style.display = "none";
    return
  }

  label.style.display = "block";
}

function fileUpload(fileInput) {
  var uppy = Uppy({
      id: fileInput.id,
      autoProceed: false,
      restrictions: {
        allowedFileTypes: fileInput.accept.split(','),
      }
    })

  uppy.use(AwsS3, {
    companionUrl: '/', // will call Shrine's presign endpoint mounted on `/s3/params`
  })

  return uppy
}

function uploadedFileData(file, response, fileInput) {
  // construct uploaded file data in the format that Shrine expects
  return JSON.stringify({
    id: file.meta['key'].match(/^cache\/(.+)/)[1], // object key without prefix
    storage: 'cache',
    metadata: {
      size:      file.size,
      filename:  file.name,
      mime_type: file.type,
    }
  })
}
